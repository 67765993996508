@import '../../../../../scss/scrollbar';

.CollapsedConnectorsMenu {
  &_ghost {
    position: absolute;
  }
  &_menu {
    position: absolute;
    width: 320px;
    background-color: var(--white);
    z-index: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--gray-200);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 1px rgba(17, 60, 92, 0.06);
    border-radius: 4px;
    padding: 8px 16px;
    max-height: 196px;
    overflow: auto;

    &_container{
      @include scrollbar();
    }

    &_item {
      display: flex;
      flex: 1;
      cursor: pointer;
      align-items: center;
      gap: 8px;


      &_name {
        color: var(--gray-800);
        width: 100%;
        font-size: 12px;
        line-height: 20px;
        padding: 2px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        direction: rtl;
        &:hover {
          background-color: var(--gray-100);
        }
        span {
          direction: ltr;
          unicode-bidi: bidi-override;
        }
      }
    }
  }
}
