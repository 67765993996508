.intent-edit-wrapper {
  padding-left: 15px;
  padding-right: 15px;
  .justui_spoiler-collapse {
    min-height: 50px;
  }
  .faq-answer-spoiler .justui_spoiler-collapse {
    display: flex;
    gap: 16px;
    margin-top: 16px;
    .faq-answer-spoiler__info__wrapper {
      padding: 12px;
      background-color: var(--grey-100);
      border-radius: 4px;
      color: var(--grey-800);
      flex: 1;
      h3 {
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 8px;
      }
      p {
        margin-bottom: 12px;
        font-size: 12px;
        line-height: 18px;
      }
    }
    .textarea-container {
      flex: 2;
      textarea {
        min-height: 100%;
      }
    }
  }
}

.content-widget-wrapper {
  margin: 0;
  height: 100%;
  display: flex;

  .content {
    height: 100%;
    padding: 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
}

.intents-footer {
  border-bottom: 1px solid var(--gray-900);
  display: flex !important;
  background: var(--gray-800) !important;
  padding: 8px 16px !important;
  flex-direction: row !important;
}

.intents-page {
  &__add-phrase-block {
    background-color: white;
    position: sticky;
    top: 8px;
    margin-top: 1rem;
    z-index: 1;
    .phrase-row__error {
      position: relative;
      left: 20px;
    }
    .intent-add-phrase-textarea {
      max-height: 250px;
      overflow-y: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  &__edit-phrase-block {
    margin: 8px 0;
    .textarea-container {
      flex: 1;
      border-radius: 0;
      padding-right: calc(1.5em + 1rem);
      border-color: var(--grey-300);
      &:focus-within {
        border-color: var(--primary);
      }
      &.form-control {
        height: 100%;
        display: grid;
        width: 100%;
        padding-left: 0.75rem;
        padding-right: 1.75rem;
        padding-top: 8px;
        padding-bottom: 8px;
        z-index: 0;
        &:not(:focus-within) {
          border-right: 1px solid transparent;
        }
        &.disabled {
          border-color: var(--grey-200);
          background-color: var(--grey-100);
          color: var(--grey-300);
          border-right: 1px solid transparent;
          z-index: 0;
        }
      }
      textarea {
        border-radius: 0;
        padding-right: 0;
      }
    }
    #paraphraseButton {
      width: 38px;
      height: 38px;
      position: absolute;
      right: 56px;
      button {
        height: 100%;
        color: var(--grey-600);
        &:hover {
          color: var(--grey-700);
        }
        margin: 0;
        display: flex;
        span {
          display: flex;
        }
      }
    }
    .loading-wrapper {
      width: 38px;
      height: 100%;
      position: absolute;
      right: 56px;
      display: flex;
      align-items: center;
      #paraphraseLoadingTooltip {
        position: relative;
        height: 38px;
        width: 100%;
      }
      .just-ui-spinner {
        background-color: transparent !important;
      }
    }
  }
}

td.intents-item-edit__table-row {
  padding-right: 0;
}

.intents-item-edit {
  &__table-row {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    &--wrapper {
      flex-grow: 1;
      outline: none;
      width: 100%;
      &:hover {
        .intents-item-edit__table-row--view {
          border: none;
          border-radius: 4px;
          box-shadow: 0 0 0 1px var(--grey-300);
          outline: none;
        }
        .intents-item-edit__table-row__change-btn {
          box-shadow: 1px 0 0 var(--grey-300);
        }
        .intents-item-edit__table-row__delete-btn {
          box-shadow: -1px 0 0 var(--grey-300);
        }
      }
    }
    &--view {
      display: flex;
      align-items: center;
      box-sizing: content-box;
      margin-right: 2px;
      .justui__icon-wrapper {
        display: flex;
        margin: 0 16px;
      }
      span.phrase-text {
        flex: 1;
        margin-left: 16px;
        word-break: break-word;
        padding-right: 2rem;
        white-space: pre-wrap;
        min-height: 21px;
        user-select: text;
        -webkit-user-select: text;
        -moz-user-select: text;
      }
    }
    &__change-btn {
      cursor: pointer;
      padding: 11px 0 11px 4px;
      &:hover {
        background-color: var(--black-4per);
      }
    }
    &__delete-btn {
      margin-left: auto;
      padding: 11px 0;
      padding-right: 4px;
      cursor: pointer;
      &:hover {
        background-color: var(--black-4per);
      }
    }
  }
  &__items-table {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    box-shadow: inset 0px -4px 4px -3px rgba(0, 0, 0, 0.14);
    .phrase-row__error {
      position: relative;
      left: 20px;
    }
    .justui-table__body {
      margin-right: -16px;
    }
    .justui-table__row {
      min-height: 56px;
    }
  }
}
.classif-rules {
  &__header {
    display: flex;
    margin-bottom: 1rem;
    h5 {
      font-weight: bold;
    }
    button.justui_button.btn-flat.btn.btn-sm {
      margin-left: auto;
      font-size: 14px;
      &:active,
      &:focus,
      &:hover {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
      }
    }
  }
}

.justui-table {
  tbody,
  &__body {
    tr:hover {
      background-color: transparent !important;
    }

    td {
      padding-left: 0;
      &:not(:last-child) {
        .just-select-wrapper {
          width: 140px;
        }
      }

      &:first-child {
        display: flex;
        align-items: center;
        padding: 5px 0;
      }

      & .IconButtons-wrap-append {
        svg {
          color: var(--gray-600);
        }
      }

      input {
        background-color: var(--gray-100);
      }
    }
  }

  thead {
    td:first-child {
      padding: 5px 0;
    }
    td {
      padding-left: 0;
    }
  }

  &__head {
    background-color: var(--grey-100);
    box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.18);
    .justui-table__row {
      min-height: 50px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--grey-200);
  }

  &__cell {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      .just-select-wrapper {
        width: 140px;
      }
    }

    &:first-child {
      display: flex;
      align-items: center;
    }

    & .IconButtons-wrap-append {
      svg {
        color: var(--gray-600);
      }
    }

    input {
      background-color: var(--gray-100);
    }
  }

  .table-row-checkbox {
    margin: 0 10px 3px;
    margin-top: -3px;
  }

  .scrollbar-container {
    padding: 0;
  }

  &.closed {
    tbody {
      tr {
        height: 38px;
      }
      td:first-child {
        .table-row-checkbox {
          margin-top: 1px;
        }
      }
    }
  }

  .table-selected {
    background-color: var(--gray-200);

    td {
      height: 50px;
    }

    color: var(--gray-950);
  }
}

.classif-rules__table {
  .justui-table__body {
    .table-row-checkbox {
      margin: 0;
    }
    tr {
      align-items: baseline;
      .just-select-wrapper {
        width: 100%;
      }
      td {
        padding: 16px 10px;
        &:not(:last-child) {
          .just-select-wrapper {
            cursor: pointer;
            input {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .justui-table__head {
    .justui-table__row:last-child {
      background-color: var(--gray-100);
    }
  }
}

.intent-answer__error {
  margin-left: -33px;
  z-index: 1;
  position: relative;
  right: 12px;
  top: 10px;
}
