@mixin green() {
  margin: 0.5rem;
  color: #215433;
  background-color: #c0e7ce;
  border-radius: 2px;

  padding: 3px 8px !important;
  line-height: 16px;

  background-color: var(--gray-100);
  color: var(--gray);
  font-size: 0.75rem;
}
@mixin blue() {
  background-color: #2289af;
  margin: 0.5rem 0.25rem;
  color: var(--white);
}

@mixin formContainer() {
  margin-top: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  & > span:first-child {
    margin-bottom: 4px;
    font-size: 12px;
    color: var(--gray);
  }

  & > div:last-child {
    width: 100%;
    min-height: 41px;
    padding: 0rem 3px 0rem 1rem;

    border: 1px solid var(--gray-200);
    border-radius: 2px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: var(--gray-100);

    & > * {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      &:last-child {
        justify-content: flex-end;
      }
    }
  }
}

.Session_container {
  margin: 0 !important;
  width: 100%;
  height: 100%;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  scroll-behavior: smooth;
  overflow: auto;
  background-color: var(--white);

  .Session_backButton {
    padding: 8px 24px;
    border-bottom: 1px solid var(--gray-200);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > *:last-child {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
    .splitter {
      height: 18px;
      width: 1px;
      left: 325.5px;
      background-color: var(--gray-200);
      margin: 0px 16px;
    }

    & > div > button {
      & > svg {
        margin-right: 0.25rem;
        margin-left: 0.25rem;
        width: 6px !important;
      }

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 0.75rem !important;
    }
  }

  .Session_head {
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    & > * {
      width: 100%;
    }
  }

  .Session_Audio {
    width: 100%;
    padding: 1rem 2rem;
    display: grid;
    grid-template-columns: 2fr 14fr;
    grid-template-rows: auto;
    background-color: var(--gray-100);
    gap: 1rem;
    position: sticky;
    top: 55px;
    z-index: 1;

    @media (max-width: 900px) {
      grid-template-columns: 2fr 8fr;
    }

    & > *:first-child {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    & > *:last-child {
      width: 100%;
    }
  }

  .audio-block {
    margin: 0 !important;

    .justui-audio-player__custom-button {
      background-color: transparent !important;
      border: none !important;

      svg * {
        fill: var(--gray);
      }
    }
  }

  .audio-progress-bar__point {
    background-color: var(--gray) !important;
  }

  .audio-progress-bar {
    background-color: var(--gray-200) !important;
  }

  .audio-progress-bar__progress {
    background-color: var(--green) !important;
  }
}

.Session_showSettingsButton {
  margin-right: 0.5rem;
  outline: none !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &:active,
  &:focus {
    outline: none !important;
    background-color: var(--gray-200) !important;
  }
}

.AnalyticActionPanel_actionPanel {
  border-top: 1px solid var(--gray-200);
  z-index: 2;
  width: 100%;
  top: 0;
  background-color: var(--white);
}

.Session_SessionInfo_container {
  height: auto;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto minmax(233px, 40%);
  gap: 16px;

  .shortNameCopyIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    transition: 200ms;
  }

  .Session_SessionInfo_sessionId_clientId {
    padding: 24px 24px 0 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    gap: 32px;

    & > * {
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: auto 2rem;

      & > *:first-child {
        grid-column: 1/3;
        color: var(--gray);
      }

      & > *:nth-child(2) {
        white-space: nowrap;
        word-break: keep-all;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .Session_SessionInfo_clientId {
      color: var(--primary);
    }
  }

  .Session_SessionInfo_botId {
    padding: 0 24px 24px 24px;
    grid-row: 2/3;
    grid-column: 1/3;
    border-bottom: 1px solid var(--gray-200);

    & > *:first-child {
      color: var(--gray);
    }
  }

  .shortNameCopyIcon {
    cursor: pointer;
  }

  .Session_SessionInfo_time {
    padding: 24px;
    grid-row: 1/3;
    grid-column: 2/3;
    border-left: 1px solid var(--gray-200);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;

    & > * > *:first-child {
      color: var(--gray);
    }
  }
}

.Session_form_item {
  &_container {
    @include formContainer();
  }
  &_green,
  &_blue {
    @include green();
  }
  &_blue {
    @include blue();
  }
}

.labelGroups_container {
  padding: 16px 24px;
  width: 100%;
  overflow: hidden;

  & > *:first-child {
    color: var(--gray);
  }

  & > *:nth-child(2) {
    background-color: var(--gray-100);
    padding: 8px;
    border: 1px solid var(--gray-200);
  }
}
